import React from "react" 
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from '@bit/azheng.joshua-tree.button';
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';
import CTA from "../components/CTA/CTA"
import SEO from "../components/seo"
import TextPhoto from "../components/TextPhoto/TextPhoto"
import { graphql } from 'gatsby'

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../cms/collections/instructions"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

function TeamMember(props) {
  return (
    <div className="column is-5">
      <ImageMeta
        cloudName="nuvolum"
        publicId={props.publicId}
        responsive
        width="auto"
        
        responsive="true"
      >

      </ImageMeta>
      <h6 className="image-caption staff">{props.staffName}</h6>
      <h6 className="image-caption staff staff-title light">
        {props.staffTitle}
      </h6>
    </div>
  )
}

function ReasonsWhyItem(props) {
  return (
    <div className="reasons-why-item columns">
      <div className="column is-4 is-hidden-mobile">
        <span className="reason-number-circle">{props.number}</span>
      </div>

      <div className="column">
        {props.heading}
        {props.blurb}
      </div>
    </div>
  )
}

function ReasonsWhyColumn(props) {
  return <div className="reasons-why-column column">{props.children}</div>
}

const AboutUsPage = (props) => {
  const post = props.data.allUniquePagesJson.nodes[0];
  return (
    <SharedStateProvider>
      <Layout>
      <SEO title={post.metaTitle} description={post.metaDescription} />
        <div className="joshua-tree-content">
          <div className="columns top-section white-back">
            <div className="column is-5"></div>
            <div className="column">
              <h1 className="mobile-left">
                {post.heading}
              </h1>
              <div dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.topBlurb)
                  )}>
            </div>
            </div>
            <div className="column is-5"></div>
          </div>
          <div className="columns">
            <div className="column is-full-width">
              <TheaterVideo
        videoUrl={`https://www.youtube.com/watch?v=${post.mainVideo.youtube}`}
                controls={true}
                playing={true}
                overVideo={true}
              >
                <NuvoImage
                  useAR
                  cloudName="nuvolum"
                  publicId={post.mainVideo.imageId}
                  width="auto"
                  responsive
                  
                  responsive="true"
                >
                </NuvoImage>
              </TheaterVideo>
            </div>
          </div>
          <div className="columns our-mission">
            <div className="column is-5"></div>
            <div className="column is-full-width" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.ourMission)
                  )}>
            </div>
            <div className="column is-5"></div>
          </div>
          <div className="color-back body-section doc-info">
            <div className="columns" style={{ paddingBottom: "80px" }}>
              <div className="column is-6"></div>
              <div className="column mobile-col" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.meetOurDoctors.text)
                  )}>
              </div>
              <div className="column is-6"></div>
            </div>
            <div className="columns">
              <div className="column is-8"></div>
              {post.meetOurDoctors.images.map((image, i) =>
                            <>
                              <div key={image.doctor.url} className="column">
                              <Link to={image.doctor.url+"/"}>
                                <ImageMeta
                                  cloudName="nuvolum"
                                  publicId={image.doctor.imageId}
                                  width="auto"
                                  responsive
                                  
                                  responsive="true"
                                >
                                </ImageMeta>
                              </Link>
                              <Link
                                className="image-link"
                                to={image.doctor.url+"/"}
                              >                              
                              <h5 className="image-caption">{image.doctor.caption}
                              </h5><span>Learn More</span>
                              </Link>
                            </div>
                            {/* {i === 0 && <div className="column is-1"></div>} */}
                            </>
                )}
              <div className="column is-8"></div>
            </div>
          </div>
          <div className="body-section reviews-block">
            <div className="columns has-text-centered about-mobile" style={{ paddingBottom: "80px" }}>
              <div className="column is-4"></div>
              <div className="column">
                <h3>
                  {post.hearWhatTheyHaveToSay.heading}
                </h3>
              </div>
              <div className="column is-4"></div>
            </div>
            <div className="columns patient-block">
              <div className="column is-8"></div>
              <div className="column">
                <TheaterVideo
        videoUrl={`https://www.youtube.com/watch?v=${post.hearWhatTheyHaveToSay.videos.patients.youtube}`}
                  controls={true}
                  playing={true}
                  overVideo={true}
                >
                  <ImageMeta
                    cloudName="nuvolum"
                    className="hoverable"
                    responsiveUseBreakpoints
                    responsive
                    publicId={post.hearWhatTheyHaveToSay.videos.patients.imageId}
                    version="1580777124"
                    width="auto"
                  >
                    
                  </ImageMeta>
                </TheaterVideo>
                <h5 className="image-caption">{post.hearWhatTheyHaveToSay.videos.patients.caption}</h5>
              </div>
              {/* <div className="column is-2"></div>
              <div className="column">
                <TheaterVideo
        videoUrl={`https://www.youtube.com/watch?v=${post.hearWhatTheyHaveToSay.videos.referringProviders.youtube}`}
                  controls={true}
                  playing={true}
                  overVideo={true}
                >
                  <ImageMeta
                    cloudName="nuvolum"
                    className="hoverable"
                    publicId={post.hearWhatTheyHaveToSay.videos.referringProviders.imageId}
                    version="1580777124"
                    width="auto"
                  >
                    
                  </ImageMeta>
                </TheaterVideo>
                <h5 className="image-caption">
                {post.hearWhatTheyHaveToSay.videos.referringProviders.caption}
                </h5>
              </div> */}
              <div className="column is-8"></div>
            </div>
          </div>

          <div className="body-section color-back reasons-why-section">
            <div className="columns" style={{ paddingBottom: "3rem" }}>
              <div className="column is-5"></div>
              <div className="column has-text-centered" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.reasonsWhySection.heading)
                  )}>
              </div>
              <div className="column is-5"></div>
            </div>
            <div className="columns is-desktop">
              <div className="column is-2"></div>

              {post.reasonsWhySection.reasonsWhyColumn.map((column, i) =>
              <>
                <ReasonsWhyColumn key={i}>
                  
                    {column.map((reason) => 
                    
                        <ReasonsWhyItem
                        key={reason.reason.number}
                        number={reason.reason.number}
                        heading={
                          <div className="reason-number-heading">
                            <span className="reason-number-circle mobile-in-heading">
                            {reason.reason.number}
                            </span>
                            <h5>{reason.reason.heading}</h5>
                          </div>
                        }
                        blurb={
                          <div dangerouslySetInnerHTML={createHtml(
                            converter.makeHtml(reason.reason.blurb)
                          )}>
                          </div>
                        }
                      />
                    )}
                  </ReasonsWhyColumn>
                {i === 0 && <div className="column is-1"></div> }
                </>
  )}
              <div className="column is-2"></div>
            </div>
          </div>

          <div className="body-section meet-our-team">
            <div className="columns" style={{ paddingBottom: "4rem" }}>
              <div className="column is-3"></div>
              <div className="column mobile-col" dangerouslySetInnerHTML={createHtml(
                            converter.makeHtml(post.meetOurTeam.text)
                          )}>
              </div>
              <div className="column is-3"></div>
            </div>

            <div className="columns staff-row">
              <div className="column is-2"></div>
              {
                post.meetOurTeam.staffImages.map((member, i) =>
                {
                  if (i < 4) {
                    return (<TeamMember
                      key={member.staffMember.imageId}
                      publicId={member.staffMember.imageId}
                      staffName={member.staffMember.staffName}
                      staffTitle={member.staffMember.staffTitle}
                    />)
                  }
              }
                )
            }
              <div className="column is-2"></div>
            </div>

            <div className="columns staff-row">
              <div className="column is-2"></div>
              {
                post.meetOurTeam.staffImages.map((member, i) =>
                {
                  if (i >= 4 && i < 8) {
                    return (<TeamMember
                      key={member.staffMember.imageId}
                      publicId={member.staffMember.imageId}
                      staffName={member.staffMember.staffName}
                      staffTitle={member.staffMember.staffTitle}
                    />)
                  }
              }
                )
            }
              <div className="column is-2"></div>
            </div>
            <div className="columns staff-row last">
              <div className="column is-2"></div>
              {
                post.meetOurTeam.staffImages.map((member, i) =>
                {
                  if (i >= 8 && i < 12) {
                    return (<TeamMember
                      key={member.staffMember.imageId}
                      publicId={member.staffMember.imageId}
                      staffName={member.staffMember.staffName}
                      staffTitle={member.staffMember.staffTitle}
                    />)
                  }
              }
                )
            }
              <div className="column is-2"></div>
            </div>
          </div>
          <section className="body-section learn-more" style={{paddingTop: "1rem"}}>
            <div className="columns">
              <div className="column is-3"></div>
              <div className="column mobile-col" dangerouslySetInnerHTML={createHtml(
                            converter.makeHtml(post.ourOffice.text)
                          )}>
              </div>
              <div className="column is-3"></div>
            </div>
            {/* <div className="columns" style={{ paddingBottom: "56px" }}>
              <div className="column is-2"></div>
              <div className="column">
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={post.ourOffice.imageId}
                  className="show-desktop-only"
                  width="auto"
                  responsive
                  
                  responsive="true"
                ></ImageMeta>
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={post.ourOffice.imageId}
                  className="show-desktop-only-inverse"
                  width="1024"
                  height="1024"
                  crop="fill"
                  responsive
                  
                  responsive="true"
                >
                  <Transformation height="400" width="auto" gravity="center" crop="fill" quality="auto" fetchFormat="auto" />
                </ImageMeta>
              </div>
              <div className="column is-2"></div>
            </div> */}

</section>

<section className="body-sections section" style={{ paddingTop: "3.375rem", paddingBottom: "3.375rem", backgroundColor: "#f7fefd" }}>
            <TextPhoto
              columnsClasses="is-vcentered"
              sideColumnIs={3}
              centerColumnIs={2}
              leftColumn={
                <div className="column is-6">
                <ImageMeta
                  cloudName="nuvolum"
                  className="is-fullwidth-mobile"
                  publicId="OSSC/DEV/about-page-badge-asird"
                  width="auto"
                >
                </ImageMeta>
              </div>
              }
              rightColumn={
                <div className="column is-9">
                <h3 style={{ marginTop: "0"}}>ASIRD Member</h3>
                <p><a href="https://www.asird.org/"title="Go to the American Society of Implant & Reconstructive Dentistry website" target="_blank">The American Society of Implant & Reconstructive Dentistry (ASIRD)</a> recognizes dentists who meet experience requirements and demonstrate optimal teamwork between dentists and oral surgeons. ASIRD surgeons are proficient in placing implants and work together efficiently throughout the process. <Link to="/matthew-r-barefoot-dds-md/">Dr. Barefoot</Link> at Oral Surgery & Dental Implant Specialists South Carolina is an ASIRD member and strives to elevate the standards of surgical and restorative dental education and practice.</p>
              </div>
              }
            />
          </section>

            <section className="body-sections section" style={{padding: "4rem 0"}}>
              <div className="columns has-text-centered">
                <div className="column is-5"></div>
                <div className="column">
                  <h3 style={{ marginBottom: "1.5rem" }}>{post.learnMore.heading}</h3>
                </div>
                <div className="column is-5"></div>
              </div>
              <div className="columns has-text-centered">
                <div className="column is-4"></div>
                <div className="column mobile-col">
                  <p>{post.learnMore.blurb}</p>
                </div>
                <div className="column is-4"></div>
              </div>

              <div className="columns">
                <div className="column is-4"></div>
                <div className="column">
                  <ButtonGroup isCentered>
                    <Button buttonText={post.learnMore.buttonOne.buttonText} href={post.learnMore.buttonOne.href} />
                    <Button buttonText={post.learnMore.buttonTwo.buttonText} href={post.learnMore.buttonTwo.href} />                    
                  </ButtonGroup>

                </div>
                <div className="column is-4"></div>
              </div>
            </section>
          
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
query AboutPage ($loneUrl: String!)  {
  allUniquePagesJson(filter: {title: {eq: $loneUrl}}) {
    nodes {
      ourMission
      heading
      youtube
      topBlurb
      meetOurTeam {
        text
        staffImages {
          staffMember {
            imageId
            staffName
            staffTitle
          }
        }
      }
      ourOffice {
        imageId
        text
      }
      reasonsWhySection {
        heading
        reasonsWhyColumn {
          reason {
            blurb
            heading
            number
          }
        }
      }
      hearWhatTheyHaveToSay {
        heading
        videos {
          patients {
            caption
            imageId
            youtube
          }
          referringProviders {
            caption
            imageId
            youtube
          }
        }
      }
      meetOurDoctors {
        images {
          doctor {
            caption
            imageId
            url
          }
        }
        text
      }
      metaTitle
      metaDescription
      title
      mainVideo {
        imageId
        youtube
      }
      learnMore {
        blurb
        buttonOne {
          buttonText
          href
        }
        buttonTwo {
          buttonText
          href
        }
        heading
      }
    }
  }
}

`

export default AboutUsPage
